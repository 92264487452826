import { useState, useEffect, useRef } from 'react'
import { MUIComponents } from '../../../muiComponents'
import WebsiteCard from './WebsiteCard'
import WebsiteNewsCard from './WebsiteNewsCard'
import { websiteCardValues } from './websiteCardValues'
import { FormControl, Grid, Input, InputLabel, MenuItem, Select } from '@mui/material';
import { useRecoilState, useRecoilValue } from "recoil";
import { positionState, fbUserObjectState, roleState, messageTypeState, startDateState, endDateState, invoiceMergerState } from "../../../../store/store";
import "suneditor/dist/css/suneditor.min.css";
import SunEditor from "suneditor-react";
import { getAuth } from 'firebase/auth'
import firebaseApp from '../../../../firebase'
import axios from 'axios'
import { NewsCardValue } from '../../../../types'
import SunEditorCore from "suneditor/src/lib/core";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import FacturenCard from './FacturenCard'

let editor: any
let editing = false
let newsCardValue: NewsCardValue
let cardEditor: SunEditorCore | undefined

const FrequentlyUsedWebsite = () => {
    const messageTypeStateString = useRecoilValue(messageTypeState);
    const [, setMessageType] = useRecoilState(messageTypeState);
    const startDateStateString = useRecoilValue(startDateState);
    const [, setStartDateType] = useRecoilState(startDateState);
    const endDateStateString = useRecoilValue(endDateState);
    const [, setEndDateType] = useRecoilState(endDateState);
    const roleStateString = useRecoilValue(roleState);
    const fbUserObject = useRecoilValue(fbUserObjectState);
    const [filterSelection, setFilterSelection] = useState<string>("Nieuws");
    const position = useRecoilValue(positionState);
    const invoiceMerger = useRecoilValue(invoiceMergerState);
    const [offset, setOffset] = useState(0);
    const [websiteCardNewsValues, setWebsiteCardNewsValues] = useState<NewsCardValue[]>([]);
    let tempWebsiteCardNewsValues: NewsCardValue[] = [];
    const [websiteCardNewsEventsValues, setWebsiteCardNewsEventsValues] = useState<NewsCardValue[]>([]);
    let tempWebsiteCardNewsEventValues: NewsCardValue[] = [];
    editor = useRef<SunEditorCore>();
    const getSunEditorInstance = (sunEditor: SunEditorCore) => {
        editor.current = sunEditor;
    };
    const [searchQuery, setSearchQuery] = useState('');
    const handleScroll = async () => {
        const windowHeight = window.innerHeight;
        const scrollPosition = window.scrollY + windowHeight;
        const documentHeight = document.body.offsetHeight;

        if (scrollPosition >= documentHeight) {
            setOffset(offset + 10);
            await fetchNews(offset + 10);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [offset]);
    const handleSearchChange = (event: any) => {
        setSearchQuery(event.currentTarget.value);
        const newsCards = websiteCardNewsValues
        newsCards.forEach((card: any) => {
            if (card.newsContent.toLowerCase().includes(event.currentTarget.value.toLowerCase()) === false) {
                card.hidden = true
            } else {
                card.hidden = false
            }
        })
        const newsEventCards = websiteCardNewsEventsValues
        newsEventCards.forEach((card: any) => {
            if (card.newsContent.toLowerCase().includes(event.currentTarget.value.toLowerCase()) === false) {
                card.hidden = true
            } else {
                card.hidden = false
            }
        })
        setWebsiteCardNewsEventsValues(newsEventCards)
    };
    const defaultFonts = [
        "Arial",
        "Comic Sans MS",
        "Courier New",
        "Impact",
        "Georgia",
        "Tahoma",
        "Trebuchet MS",
        "Verdana"
    ];
    function handleBlur(event: any, editorContents: string) {
        setValue(editorContents);
    }
    function handleMessageTypeChange(event: any) {
        const value = event.target.value;
        setMessageType(value);
    }
    function handleStartDateChange(event: any) {
        const date = new Date(event);
        const formattedDate = `${(date.getDate()).toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} ${(date.getHours()).toString().padStart(2, '0')}:${(date.getMinutes()).toString().padStart(2, '0')}`
        setStartDateType(formattedDate)
    }
    function handleEndDateChange(event: any) {
        const date = new Date(event);
        const formattedDate = `${(date.getDate()).toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} ${(date.getHours()).toString().padStart(2, '0')}:${(date.getMinutes()).toString().padStart(2, '0')}`
        setEndDateType(formattedDate)
    }
    const [value, setValue] = useState("");
    const sortedFontOptions = [
        "BasierCircle",
        "Logical",
        "Salesforce Sans",
        "Garamond",
        "Sans-Serif",
        "Serif",
        "Times New Roman",
        "Helvetica",
        ...defaultFonts
    ].sort();
    const checkSelectedStyle = (selected: boolean) => ({
        fontSize: 11,
        width: 'auto',
        height: 30,
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
        borderRadius: "2px",
        fontWeight: "bold",
        margin: '0.5rem',
        fontFamily: 'BasierCircle, Arial, sans-serif',
        backgroundColor: selected ? "white" : "#005C6C",
        color: selected ? "#005C6C" : "white",
        border: selected ? "1.5px solid rgba(20, 88, 100, 0.5)" : "1.5px solid transparent"
    })

    const fetchNews = async (offset: number) => {
        try {
            const response = await axios.post(
                "https://europe-west1-fourfiveteamleader.cloudfunctions.net/nexuelasticProxy",
                {
                    action: "getNewsOffset",
                    offset: offset,
                },
                {
                    headers: {
                        uid: fbUserObject?.providerData[0]?.uid,
                        "access-token": fbUserObject?.accessToken ?? "",
                    },
                }
            );
            response.data.forEach((element: NewsCardValue) => {
                if (element.endDate != null && element.messageType === "Waarschuwing") {
                    const [day, month, year] = element.endDate.split('/');
                    const endDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day)); // Note: month is 0-based in JavaScript
                    endDate.setDate(endDate.getDate() + 1);
                    const currentDate = new Date();
                    if (endDate < currentDate) {
                        response.data = response.data.filter((item: { id: number }) => item.id !== element.id);
                    }
                }
            })
            if (response.data.length === 0) {
                return
            }
            const newValues = [...response.data, ...tempWebsiteCardNewsValues];
            const sortedValues = newValues.sort((a: NewsCardValue, b: NewsCardValue) => {
                if (a.messageType === b.messageType) {
                    return b.id - a.id;
                } else {
                    if (a.messageType === "Nieuws") {
                        return 1;
                    } else {
                        return -1;
                    }
                }
            });
            tempWebsiteCardNewsValues = sortedValues
            tempWebsiteCardNewsValues = tempWebsiteCardNewsValues.filter(
                (v, i, a) => a.findIndex((t) => t.id === v.id) === i
            )
            setWebsiteCardNewsValues(tempWebsiteCardNewsValues);
        } catch (error) {
            console.log(error);
            throw error;
        }
    };
    const fetchEventNews = async (offset: number) => {
        try {
            const response = await axios.post(
                "https://europe-west1-fourfiveteamleader.cloudfunctions.net/nexuelasticProxy",
                {
                    action: "getNewsEvents",
                    offset: offset,
                },
                {
                    headers: {
                        uid: fbUserObject?.providerData[0]?.uid,
                        "access-token": fbUserObject?.accessToken ?? "",
                    },
                }
            );
            response.data.forEach((element: NewsCardValue) => {
                if (element.endDate != null) {
                    const [day, month, year] = element.endDate.split('/');
                    const endDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day)); // Note: month is 0-based in JavaScript
                    endDate.setDate(endDate.getDate() + 1);
                    const currentDate = new Date();
                    if (endDate < currentDate) {
                        response.data = response.data.filter((item: { id: number }) => item.id !== element.id);
                    }
                }
            })
            const newValues = [...response.data, ...tempWebsiteCardNewsEventValues];
            const sortedValues = newValues.sort((a: NewsCardValue, b: NewsCardValue) => {
                const [dayA, monthA, yearA] = (a.startDate ?? '').split('/');
                const [dayB, monthB, yearB] = (b.startDate ?? '').split('/');
                const startDateA = new Date(parseInt(yearA), parseInt(monthA) - 1, parseInt(dayA));
                const startDateB = new Date(parseInt(yearB), parseInt(monthB) - 1, parseInt(dayB));
                return startDateA.getTime() - startDateB.getTime();
            })
            tempWebsiteCardNewsEventValues = sortedValues
            tempWebsiteCardNewsEventValues = tempWebsiteCardNewsEventValues.filter(
                (v, i, a) => a.findIndex((t) => t.id === v.id) === i
            )
            setWebsiteCardNewsEventsValues(tempWebsiteCardNewsEventValues);
        } catch (error) {
            console.log(error);
            throw error;
        }
    };
    useEffect(() => {
        const fetchNewsAsync = async () => {
            await fetchNews(0);
            await fetchEventNews(0);
        };
        if (offset === 0) {
            fetchNewsAsync();
        }
    }, [fbUserObject]);
    return (
        <>
            <div>
                <h3 style={{ textAlign: 'left', color: '#005C6C' }}>Veelgebruikte websites en toepassingen</h3>
            </div>
            <div style={{ display: 'block', textAlign: 'left' }} id="myBtnContainer">
                <MUIComponents.Buttons.Button
                    id="Nieuws"
                    variant="outlined"
                    onClick={() => setFilterSelection("Nieuws")}
                    style={checkSelectedStyle(filterSelection === "Nieuws")}>
                    Nieuws
                </MUIComponents.Buttons.Button>
                {
                    invoiceMerger.toLowerCase() === 'yes' && <>
                        <MUIComponents.Buttons.Button
                            id="Facturen"
                            variant="outlined"
                            onClick={() => setFilterSelection("Facturen")}
                            style={checkSelectedStyle(filterSelection === "Facturen")}>
                            Facturen samenvoegen
                        </MUIComponents.Buttons.Button>
                    </>
                }
                <MUIComponents.Buttons.Button
                    id="Digitaal"
                    variant="outlined"
                    onClick={() => setFilterSelection("Digitaal")}
                    style={checkSelectedStyle(filterSelection === "Digitaal")}>
                    Digitaal tekenen
                </MUIComponents.Buttons.Button>
                <MUIComponents.Buttons.Button
                    id="Admin"
                    variant="outlined"
                    onClick={() => setFilterSelection("Admin")}
                    style={checkSelectedStyle(filterSelection === "Admin")}>
                    Admin
                </MUIComponents.Buttons.Button>
                <MUIComponents.Buttons.Button
                    id="GDPR"
                    variant="outlined"
                    onClick={() => setFilterSelection("GDPR")}
                    style={checkSelectedStyle(filterSelection === "GDPR")}>
                    GDPR
                </MUIComponents.Buttons.Button>
                <MUIComponents.Buttons.Button
                    id="Kennis"
                    variant="outlined"
                    onClick={() => setFilterSelection("Kennis")}
                    style={checkSelectedStyle(filterSelection === "Kennis")}>
                    Kennis
                </MUIComponents.Buttons.Button>
                <MUIComponents.Buttons.Button
                    id="Ondernemingsinfo"
                    variant="outlined"
                    onClick={() => setFilterSelection("Ondernemingsinfo")}
                    style={checkSelectedStyle(filterSelection === "Ondernemingsinfo")}>
                    Ondernemingsinfo
                </MUIComponents.Buttons.Button>
                <MUIComponents.Buttons.Button
                    id="Vertalingen"
                    variant="outlined"
                    onClick={() => setFilterSelection("Vertalingen")}
                    style={checkSelectedStyle(filterSelection === "Vertalingen")}>
                    Vertalingen
                </MUIComponents.Buttons.Button>
                {
                    position.toLowerCase() === 'vennoot' && <>
                        <MUIComponents.Buttons.Button
                            id="Vennoten"
                            variant="outlined"
                            onClick={() => setFilterSelection("Vennoten")}
                            style={checkSelectedStyle(filterSelection === "Vennoten")}>
                            Vennoten
                        </MUIComponents.Buttons.Button>
                    </>
                }
            </div>
            <div>
                <Grid container columns={{ xs: 4, sm: 8, md: 12 }} style={{ paddingBottom: '1em' }}>
                    {
                        (filterSelection !== "Nieuws") ? (
                            (filterSelection !== "Facturen") ? (
                                websiteCardValues.map((cardValue) => {
                                    if (filterSelection === cardValue?.category) {
                                        return <WebsiteCard key={cardValue.id} cardValue={cardValue} />;
                                    }
                                    return null; // Ensure a value is always returned
                                })
                            ) : (
                                websiteCardValues.map((cardValue) => {
                                    if (filterSelection === cardValue?.category) {
                                        return <FacturenCard key={cardValue.id} cardValue={cardValue} />;
                                    }
                                    return null; // Ensure a value is always returned
                                })
                            )) : (
                            <>
                                {
                                    (roleStateString === "admin" || roleStateString === "news" || roleStateString === "globalnews") ? (
                                        <div style={{ width: "100%", height: "100%", backgroundColor: "#E2EAEC", textAlign: 'left' }}>
                                            <SunEditor
                                                setDefaultStyle='font-family: "BasierCircle", sans-serif;'
                                                getSunEditorInstance={getSunEditorInstance}
                                                setContents={value}
                                                onChange={setValue}
                                                onBlur={handleBlur}
                                                setOptions={{
                                                    buttonList: [
                                                        ["undo", "redo"],
                                                        ["font", "fontSize"],
                                                        // ['paragraphStyle', 'blockquote'],
                                                        [
                                                            "bold",
                                                            "underline",
                                                            "italic",
                                                            "strike",
                                                            "subscript",
                                                            "superscript"
                                                        ],
                                                        ["fontColor", "hiliteColor"],
                                                        ["align", "list", "lineHeight"],
                                                        ["outdent", "indent"],

                                                        ["table", "horizontalRule", "link", "image", "video"],
                                                        // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                                                        // ['imageGallery'], // You must add the "imageGalleryUrl".
                                                        //["fullScreen", "showBlocks", "codeView"],
                                                        //["preview"],
                                                        ["print"],
                                                        ["removeFormat"],

                                                        //['save', 'template'],
                                                        // '/', Line break
                                                    ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                                                    defaultTag: "div",
                                                    height: "auto",
                                                    minHeight: "100px",
                                                    maxWidth: "100vw",
                                                    showPathLabel: false,
                                                    font: sortedFontOptions
                                                }}
                                            />
                                            <div style={{ textAlign: 'center', marginTop: '0.5em', height: '2.5em' }}>
                                                <FormControl style={{ marginRight: '.5em', minWidth: "10em", backgroundColor: "white", height: "100%" }}>
                                                    <InputLabel id="search-label" >Zoeken</InputLabel>
                                                    <Input
                                                        id="search-input"
                                                        type="search"
                                                        value={searchQuery}
                                                        onChange={handleSearchChange}
                                                        style={{ minWidth: "100%", height: "100%", marginBottom: '0em', marginTop: '0em' }}
                                                    />
                                                </FormControl>
                                                <FormControl style={{ minWidth: "6em", backgroundColor: "white", height: "100%" }}>
                                                    <InputLabel id="select-message-type-label" >Berichttype</InputLabel>
                                                    <Select
                                                        labelId="select-message-type-label"
                                                        id="select-message-type"
                                                        value={messageTypeStateString}
                                                        autoWidth
                                                        style={{ minWidth: "100%", height: "100%" }}
                                                        defaultValue="Mededeling"
                                                        onChange={handleMessageTypeChange}>
                                                        <MenuItem value="Mededeling">Mededeling</MenuItem>
                                                        <MenuItem value="Waarschuwing">Waarschuwing</MenuItem>
                                                        <MenuItem value="Evenement">Evenement</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                {
                                                    (messageTypeStateString == "Evenement" || messageTypeStateString == "Waarschuwing") ? (
                                                        <>
                                                            <DateTimePicker
                                                                format='dd/MM/yyyy HH:mm'
                                                                value={new Date(parseInt(startDateStateString.split("/")[2]), parseInt(startDateStateString.split("/")[1]) - 1, parseInt(startDateStateString.split("/")[0]), parseInt(startDateStateString?.split(" ")[1]?.split(":")[0]) || 0, parseInt(startDateStateString?.split(" ")[1]?.split(":")[1]) || 0)}
                                                                label="Startdatum"
                                                                ampm={false}
                                                                onChange={handleStartDateChange}
                                                                defaultValue={new Date()}
                                                                slotProps={{
                                                                    textField: {
                                                                        size: "small",
                                                                    }
                                                                }}
                                                                sx={{ width: "12.5em", backgroundColor: "white", height: "100%", marginLeft: "0.5em" }} />
                                                            <DateTimePicker
                                                                format='dd/MM/yyyy HH:mm'
                                                                value={new Date(parseInt(endDateStateString.split("/")[2]), parseInt(endDateStateString.split("/")[1]) - 1, parseInt(endDateStateString.split("/")[0]), parseInt(endDateStateString?.split(" ")[1]?.split(":")[0]) || 0, parseInt(endDateStateString?.split(" ")[1]?.split(":")[1]) || 0)}
                                                                label="Einddatum"
                                                                ampm={false}
                                                                onChange={handleEndDateChange}
                                                                defaultValue={new Date()}
                                                                slotProps={{
                                                                    textField: {
                                                                        size: "small",
                                                                    }
                                                                }}
                                                                sx={{ width: "12.5em", backgroundColor: "white", height: "100%", marginLeft: "0.5em" }} />
                                                        </>
                                                    ) : null
                                                }
                                                <MUIComponents.Buttons.Button
                                                    id="Save"
                                                    variant="outlined"
                                                    onClick={async () => {
                                                        const auth = getAuth(firebaseApp)
                                                        const name = (auth.currentUser?.displayName) ? (auth.currentUser?.displayName) : ("onbekend");
                                                        if (value.length > 0 && value !== "" && value.toString() != '<div><br></div>') {
                                                            if (editing) {
                                                                editing = false;
                                                                cardEditor?.core.setContents(value);
                                                                if (messageTypeStateString === "Evenement") {
                                                                    websiteCardNewsEventsValues[websiteCardNewsEventsValues.indexOf(newsCardValue)] = { id: newsCardValue.id, name: name, newsContent: value, date: newsCardValue.date, messageType: messageTypeStateString, email: auth.currentUser?.email || "", startDate: startDateStateString, endDate: endDateStateString };
                                                                }
                                                                else {
                                                                    websiteCardNewsValues[websiteCardNewsValues.indexOf(newsCardValue)] = { id: newsCardValue.id, name: name, newsContent: value, date: newsCardValue.date, messageType: messageTypeStateString, email: auth.currentUser?.email || "", startDate: startDateStateString, endDate: endDateStateString };
                                                                }
                                                                await axios.post('https://europe-west1-fourfiveteamleader.cloudfunctions.net/nexuelasticProxy', {
                                                                    action: 'updateNews',
                                                                    news: { id: newsCardValue.id, name: name, newsContent: value, date: newsCardValue.date, messageType: messageTypeStateString, email: auth.currentUser?.email, startDate: startDateStateString, endDate: endDateStateString }
                                                                }, {
                                                                    headers: {
                                                                        uid: fbUserObject?.providerData[0]?.uid,
                                                                        'access-token': fbUserObject?.accessToken ?? ''
                                                                    }
                                                                })
                                                            }
                                                            else {
                                                                if (messageTypeStateString === "Evenement") {
                                                                    websiteCardNewsEventsValues.unshift({ id: Date.now(), name: name, newsContent: value, date: new Date().toLocaleDateString(), messageType: messageTypeStateString, email: auth.currentUser?.email || "", startDate: startDateStateString, endDate: endDateStateString });
                                                                }
                                                                else {
                                                                    websiteCardNewsValues.unshift({ id: Date.now(), name: name, newsContent: value, date: new Date().toLocaleDateString(), messageType: messageTypeStateString, email: auth.currentUser?.email || "", startDate: startDateStateString, endDate: endDateStateString });
                                                                }
                                                                await axios.post('https://europe-west1-fourfiveteamleader.cloudfunctions.net/nexuelasticProxy', {
                                                                    action: 'postNews',
                                                                    news: { id: Date.now(), name: name, newsContent: value, date: new Date().toLocaleDateString(), messageType: messageTypeStateString, email: auth.currentUser?.email, startDate: startDateStateString, endDate: endDateStateString }
                                                                }, {
                                                                    headers: {
                                                                        uid: fbUserObject?.providerData[0]?.uid,
                                                                        'access-token': fbUserObject?.accessToken ?? ''
                                                                    }
                                                                })
                                                            }
                                                            setValue('');
                                                        }
                                                    }}
                                                    style={{
                                                        ...checkSelectedStyle(filterSelection === "Nieuws"), height: "100%", marginTop: '0em', marginBottom: '0em',
                                                    }}>
                                                    {editing ? "Bericht aanpassen" : "Bericht toevoegen"}
                                                </MUIComponents.Buttons.Button>
                                            </div>
                                        </div>) : (null)}

                                {
                                    <Grid container spacing={2}>
                                        <Grid item xs={9} style={{ paddingRight: "2.5%" }}>
                                            <h3 style={{ marginTop: "0.25em", marginBottom: "0em" }}>Nieuws</h3>
                                            {websiteCardNewsValues.map((cardValue: NewsCardValue) => {
                                                if (!cardValue.hidden) {
                                                    return <WebsiteNewsCard key={cardValue.id} cardValue={cardValue} />;
                                                }
                                                return null;
                                            })}
                                        </Grid>
                                        <Grid item xs={3} style={{ paddingLeft: "0px" }}>
                                            <h3 style={{ marginTop: "0.25em", marginBottom: "0em" }}>Evenementen</h3>
                                            {websiteCardNewsEventsValues.map((cardValue: NewsCardValue) => {
                                                if (!cardValue.hidden) {
                                                    return <WebsiteNewsCard key={cardValue.id} cardValue={cardValue} />;
                                                }
                                                return null;
                                            })}
                                        </Grid>
                                    </Grid>
                                }
                            </>
                        )
                    }
                </Grid>
            </div >
        </>
    )
}
export function CardValues(value: NewsCardValue, cardEditorValue: SunEditorCore | undefined) {
    newsCardValue = value;
    editing = true;
    editor.current?.core.setContents(newsCardValue.newsContent);
    cardEditor = cardEditorValue;
}
export default FrequentlyUsedWebsite